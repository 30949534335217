import React, {useEffect, useState, useContext} from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GreenButton } from '../../components/components/Buttons'
import RisingSpans from '../../components/components/RisingSpans'
import {motion, useAnimation, usePresence, AnimatePresence} from 'framer-motion'
import {achievementsHolder } from '../pages.module.scss'

import {SectionNavContext} from '../../components/utilities/SiteContexts'

const liVariants = {
  pre: {
    opacity:0,
    y:'10rem'
  },
  vis: {
    y:0,
    opacity:1,
    transition:{
      duration:1.5
    }
  },
  post: {
    opacity:0,
    y:'-10rem',
    transition:{
      duration:1.5
    }
  }
}

const sunSvgVariants = {
  pre: {
    y:'50vh',
    opacity:0
  },
  vis: {
    y:0,
    opacity:1,
    transition:{
      duration:1.5
    }
  },
  post: {
    y:'50vh',
    transition:{
      duration:1.5
    }
  }
}

const sunPathVariants = {
  pre: {
    pathLength:1
  },
  vis: {
    pathLength:[1,1,0,1],
    transition:{
      times: [0, 0.1, 0.9, 1],
      duration:6.5,
      repeat: Infinity,
      repeatType:'loop'
    }
  },
  post: {
    pathLength:1
  }
}

const articleVariants = {
  pre: {
    opacity:0
  },
  vis: {
    opacity:1,
    transition: {
      duration:1
    }
  },
  post: {
    opacity:0
  }
}

const buttonVariants = {
  pre: {
    opacity:0,
    y: '4rem'
  },
  vis: {
    opacity:1,
    y: '0rem',
    transition: {
      duration:1
    }
  },
  post: {
    y: '-4rem',
    opacity:0,
    transition: {
      duration:1
    }
  }
}


const achievements = [
  {
    text: 'CCS led the delivery of the largest solar energy site in the UK*',
    subText: '* at time of connection',
    id: 1
  },
  {
    text: 'CCS led the delivery of the UK’s first site designed and constructed at 1500v in 2013.',
    subText: '',
    id: 2
  },
  {
    text: 'CCS led the delivery of the UK’s first 132kv connection for a solar park',
    subText: '',
    id: 3
  }
]

export default function AchievementsPage() {

  const [,setSectionNav] = useContext(SectionNavContext)
  const [showIndex, setShowIndex] = useState(0)
  const sunSvgControl = useAnimation()
  const sunPathControl = useAnimation()
  const articleControl = useAnimation()

  const [isPresent, safeToRemove] = usePresence()


const introAnimation = async () => {
    await articleControl.start('vis')
    sunSvgControl.start('vis')
    sunPathControl.start('vis')
}


useEffect(() => {
  introAnimation()
  setSectionNav([
    {
      to: '/',
      title: 'Home'
    },
    {
      to: '/about/video',
      title: 'Video'
    },
    { 
      to: '/about/benefits',
      title: 'Benefits'
    },
    {
      to: '/about/expertise',
      title: 'Expertise',
    },
    {
      to: '/about/achievements',
      title: 'Achievements',
      active: true
    },
    {
      to: '/about/contact',
      title: 'Contact'
    }
  ])
}, [])

  useEffect(()=>{
    
    const intervalID = setInterval( () => setShowIndex(showIndex => {
      
      if(showIndex === achievements.length - 1) {
        return 0
      } else {
        return showIndex + 1
      }

    }), 6500 )

    return ()=>clearInterval(intervalID)

  },[setShowIndex])

  useEffect(() => {

    const exitAnimation = async (setShowIndex) => {
      setShowIndex(null)
      await sunSvgControl.start('post')
      await articleControl.start('post')

      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation(setShowIndex)
    }
  }, [isPresent, setShowIndex, sunSvgControl, articleControl, safeToRemove])


  return (
    <motion.article variants={articleVariants} initial="pre" animate={articleControl} className={achievementsHolder}>
        <ul>
          <AnimatePresence exitBeforeEnter>
        {
          achievements.map((achievement, index) => {
            if(showIndex === index) {
              return (<motion.li key={achievement.id} variants={liVariants} initial="pre" animate="vis" exit="post" onClick={()=>{setShowIndex(showIndex+1)}}>
                      <RisingSpans staggerChildren={0.1} exitAnimation={true}><h2>{achievement.text}</h2></RisingSpans>
                      {/* <h2 className="test">{achievement.text}</h2> */}
                      { achievement.subText && <motion.div className="subText">{achievement.subText}</motion.div> }
                    </motion.li>
                )
              }
            })
          }
          </AnimatePresence>
        </ul>
      <motion.div className="button" variants={buttonVariants} initial="pre" exit="post" animate="vis">
        <GreenButton to="/our-projects" alt="Our projects">
          Our Projects
        </GreenButton>
      </motion.div>
      <motion.div className="sky" >
        <StaticImage
        src="../../images/about/achievements-bluesky.jpg"
        alt="Blue Sky"
        />
      </motion.div>

      <motion.div className="panels" >
      <StaticImage
        className="panels"
        src="../../images/about/achievements-panels.jpg"
        alt="Solar Panels"
        />
        </motion.div>

        <motion.svg animate={sunSvgControl} variants={sunSvgVariants} initial="pre" className="dark" width="261" height="261" viewBox="0 0 261 261" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path animate={sunPathControl} variants={sunPathVariants} initial="pre" d="M130.504 0.996637C59.0297 0.996641 1.00292 59.0252 1.00292 130.499C1.00293 201.975 59.0298 260.003 130.504 260.003C201.976 260.003 260.003 201.975 260.003 130.499C260.003 59.0252 201.976 0.996634 130.504 0.996637Z" stroke="currentColor" strokeWidth="1"  />
        </motion.svg>


    </motion.article>
  )
}



